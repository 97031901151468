import {
    UPLOAD_CSV_FILE_REQUEST,
    UPLOAD_CSV_FILE_SUCCESS,
    UPLOAD_CSV_FILE_FAILURE,
    GET_QUEUED_LIST_REQUEST,
    GET_QUEUED_LIST_SUCCESS,
    GET_QUEUED_LIST_FAILURE,
    ENQUEUED_LIST_REQUEST,
    ENQUEUED_LIST_SUCCESS,
    ENQUEUED_LIST_FAILURE,
    GET_LIST_STATS_REQUEST,
    GET_LIST_STATS_SUCCESS,
    GET_LIST_STATS_FAILURE,
    SET_LIST_STATS,
    VALIDATE_EMAILS_REQUEST,
    VALIDATE_EMAILS_SUCCESS,
    VALIDATE_EMAILS_FAILURE,
    DELETE_LIST_REQUEST,
    DELETE_LIST_SUCCESS,
    DELETE_LIST_FAILURE,
    TERMINATE_LIST_VALIDATION_REQUEST,
    TERMINATE_LIST_VALIDATION_SUCCESS,
    TERMINATE_LIST_VALIDATION_FAILURE
    
} from '../actions/EmailsListActions'
import { VALIDATE_EMAIL_FAILURE } from '../actions/emailValidationActions';

// Initial state
const initialState = { loading: false, error: {} };

// Reducer
export default function EmailsListReducer(state = initialState, action = {}) {
    switch (action.type) {

        case UPLOAD_CSV_FILE_REQUEST:
        case GET_QUEUED_LIST_REQUEST:
        case ENQUEUED_LIST_REQUEST:
        case VALIDATE_EMAILS_REQUEST:
        case DELETE_LIST_REQUEST:
        case TERMINATE_LIST_VALIDATION_REQUEST:
            return { ...state, loading: true, error: false };

        case GET_LIST_STATS_REQUEST:
            return { ...state, loading: true, error: false, listStats: undefined };

        case UPLOAD_CSV_FILE_SUCCESS:
            return { ...state, file: action.payload, error: false, loading: false };

        case GET_QUEUED_LIST_SUCCESS:
            return { ...state, queuedLists: action.payload, error: false, loading: false };

        case GET_QUEUED_LIST_SUCCESS:
            return { ...state, listStats: action.payload };

        case ENQUEUED_LIST_SUCCESS:
            return { ...state, queuedEmails: action.payload, error: false, loading: false };

        case GET_LIST_STATS_SUCCESS:
        case SET_LIST_STATS:
            return { ...state, listStats: action.payload, error: false, loading: false };

        case VALIDATE_EMAILS_SUCCESS:
        case DELETE_LIST_SUCCESS:
        case TERMINATE_LIST_VALIDATION_SUCCESS:
            return { ...state, error: false, loading: false };

        case UPLOAD_CSV_FILE_FAILURE:
        case GET_QUEUED_LIST_FAILURE:
        case ENQUEUED_LIST_FAILURE:
        case GET_LIST_STATS_FAILURE:
        case VALIDATE_EMAILS_FAILURE:
        case DELETE_LIST_FAILURE:
        case TERMINATE_LIST_VALIDATION_FAILURE:
            return { ...state, loading: false, error: action.payload, file: undefined };

        default:
            return state
    }

}