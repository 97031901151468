import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import './App.scss';
import AppRoutes from './AppRoutes';
import Navbar from './shared/Navbar';
import Sidebar from './shared/Sidebar';
import Footer from './shared/Footer';
import { clearToken, clearUser, getUser } from '../utils/authentication';

const fullPageLayoutRoutes = ['/login', '/signup', '/verify-email', '/user-pages/lockscreen', '/error-404', '/error-500', '/landing-page', '/reset-password'];

class App extends Component {
  state = {}
  componentDidMount() {
    this.onRouteChanged();
  }
  render () {
    let navbarComponent = !this.state.isFullPageLayout ? <Navbar {...this.props}/> : '';
    let sidebarComponent = !this.state.isFullPageLayout ? <Sidebar {...this.props}/> : '';
    let footerComponent = !this.state.isFullPageLayout ? <Footer {...this.props}/> : '';
    return (
      <div className="container-scroller">
        { navbarComponent }
        <div className="container-fluid page-body-wrapper">
          { sidebarComponent }
          <div className="main-panel">
            <div className="content-wrapper">
              <AppRoutes/>
            </div>
          </div>
          { footerComponent }
        </div>
      </div>
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    const user = getUser()

    // logout if login or signup route visited
    if(this.props.location.pathname === '/login' || this.props.location.pathname === '/signup') {
      clearToken()
      clearUser()
    } else if(user && user.email_verified !== "1" && this.props.location.pathname !== '/verify-email') {
      this.props.history.push("/verify-email")
      return
    }
    
    window.scrollTo(0, 0);
    for ( let i = 0; i < fullPageLayoutRoutes.length; i++ ) {
      if (this.props.location.pathname.startsWith(fullPageLayoutRoutes[i])) {
        this.setState({
          isFullPageLayout: true
        })
        document.querySelector('.page-body-wrapper').classList.add('full-page-wrapper');
        break;
      } else {
        this.setState({
          isFullPageLayout: false
        })
        document.querySelector('.page-body-wrapper').classList.remove('full-page-wrapper');
      }
    }
  }

}

export default withRouter(App);
