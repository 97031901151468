import {
    UPDATE_CREDITS_BALANCE,
    GET_CREDITS_BALANCE_REQUEST,
    GET_CREDITS_BALANCE_SUCCESS,
    GET_CREDITS_BALANCE_FAILURE,
    SYNC_CREDIT_BALANCE_REQUEST,
    SYNC_CREDIT_BALANCE_SUCCESS,
    SYNC_CREDIT_BALANCE_FAILURE,
    GET_CREDITS_SPEND_HISTORY_REQUEST,
    GET_CREDITS_SPEND_HISTORY_SUCCESS,
    GET_CREDITS_SPEND_HISTORY_FAILURE,
    GET_CREDITS_PURCHASE_HISTORY_REQUEST,
    GET_CREDITS_PURCHASE_HISTORY_SUCCESS,
    GET_CREDITS_PURCHASE_HISTORY_FAILURE
} from '../actions/CreditsActions'

// Initial state
const initialState = { loading: false, error: {}, user: false };

// Reducer
export default function CreditsReducer(state = initialState, action = {}) {

    switch (action.type) {

        case GET_CREDITS_SPEND_HISTORY_REQUEST:
        case GET_CREDITS_PURCHASE_HISTORY_REQUEST:
        case GET_CREDITS_BALANCE_REQUEST:
            return {...state, loading: true, error: false}

        case GET_CREDITS_SPEND_HISTORY_SUCCESS:
            return {...state, loading: false, creditsSpent: action.payload}
            
        case GET_CREDITS_PURCHASE_HISTORY_SUCCESS:
            return {...state, loading: false, creditsPurchased: action.payload}

        case UPDATE_CREDITS_BALANCE:
            return { ...state, creditsBalance: action.payload };

        case SYNC_CREDIT_BALANCE_REQUEST:
            return { ...state, loading: true, error: false };

        case GET_CREDITS_BALANCE_SUCCESS:
        case SYNC_CREDIT_BALANCE_SUCCESS:
            return { ...state, creditsBalance: action.payload, error: false };

        case SYNC_CREDIT_BALANCE_FAILURE:
            return { ...state, loading: false, error: action.payload };

        case GET_CREDITS_PURCHASE_HISTORY_FAILURE:
        case GET_CREDITS_SPEND_HISTORY_FAILURE:
        case GET_CREDITS_BALANCE_FAILURE:
            return {...state, loading: false, error: action.payload}

        default:
            return state
    }

}