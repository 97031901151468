import * as UsersService from '../../services/users'

export const UPDATE_CREDITS_BALANCE = 'UPDATE_CREDITS_BALANCE'
export const GET_CREDITS_BALANCE_REQUEST = 'GET_CREDITS_BALANCE_REQUEST'
export const GET_CREDITS_BALANCE_SUCCESS = 'GET_CREDITS_BALANCE_SUCCESS'
export const GET_CREDITS_BALANCE_FAILURE = 'GET_CREDITS_BALANCE_FAILURE'
export const SYNC_CREDIT_BALANCE_REQUEST = 'SYNC_CREDIT_BALANCE_REQUEST'
export const SYNC_CREDIT_BALANCE_SUCCESS = 'SYNC_CREDIT_BALANCE_SUCCESS'
export const SYNC_CREDIT_BALANCE_FAILURE = 'SYNC_CREDIT_BALANCE_FAILURE'
export const GET_CREDITS_SPEND_HISTORY_REQUEST = 'GET_CREDITS_SPEND_HISTORY_REQUEST'
export const GET_CREDITS_SPEND_HISTORY_SUCCESS = 'GET_CREDITS_SPEND_HISTORY_SUCCESS'
export const GET_CREDITS_SPEND_HISTORY_FAILURE = 'GET_CREDITS_SPEND_HISTORY_FAILURE'
export const GET_CREDITS_PURCHASE_HISTORY_REQUEST = 'GET_CREDITS_PURCHASE_HISTORY_REQUEST'
export const GET_CREDITS_PURCHASE_HISTORY_SUCCESS = 'GET_CREDITS_PURCHASE_HISTORY_SUCCESS'
export const GET_CREDITS_PURCHASE_HISTORY_FAILURE = 'GET_CREDITS_PURCHASE_HISTORY_FAILURE'

export function updateCreditsCount(newCount) {
  return dispatch => {
    dispatch({type: UPDATE_CREDITS_BALANCE, payload: newCount})
  };
}

export function getCreditsBalance(callback) {
  return dispatch => {
    dispatch({ type: GET_CREDITS_BALANCE_REQUEST })
    return UsersService.getCreditsBalance().then(data => {
      if(callback) callback({status: true, data})
      dispatch({ type: GET_CREDITS_BALANCE_SUCCESS, payload: data })
    }).catch(error => {
      if(callback) callback({status: false, data: error.message})
      dispatch({ type: GET_CREDITS_BALANCE_FAILURE, payload: error.message })
    });
  };
}

export function getCreditsSpendHistory() {
  return dispatch => {
    dispatch({ type: GET_CREDITS_SPEND_HISTORY_REQUEST })
    return UsersService.getCreditsSpendHistory().then(data => {
      dispatch({ type: GET_CREDITS_SPEND_HISTORY_SUCCESS, payload: data })
    }).catch(error => {
      dispatch({ type: GET_CREDITS_SPEND_HISTORY_FAILURE, payload: error.message })
    });
  };
}

export function getCreditsPurchaseHistory() {
  return dispatch => {
    dispatch({ type: GET_CREDITS_PURCHASE_HISTORY_REQUEST })
    return UsersService.getCreditsPurchaseHistory().then(data => {
      dispatch({ type: GET_CREDITS_PURCHASE_HISTORY_SUCCESS, payload: data })
    }).catch(error => {
      dispatch({ type: GET_CREDITS_PURCHASE_HISTORY_FAILURE, payload: error.message })
    });
  };
}