import { get, post } from '../utils/api'
import Config from '../utils/configuration'
import { getToken } from '../utils/authentication'

export const validateEmail = (payload) => new Promise((resolve, reject) => {
    post(`/v1/validate/email`, payload)
        .then((response) => {
            if (response.status === 1) {
                resolve(response.data)
            } else {
                reject({ message: response.message })
            }
        })
        .catch((error) => {
            reject(error)
        });
})

export const getQueuedLists = () => new Promise((resolve, reject) => {
    get(`/v1/process/queued-lists`)
        .then((data) => {
            if (data.status === 1) {
                resolve(data.data)
            } else {
                reject({ message: data.message })
            }
        })
        .catch((error) => {
            reject(error)
        });
})

export const enqueueList = (payload) => new Promise((resolve, reject) => {
    post(`/v1/process/enqueue-list`, payload)
        .then((data) => {
            if (data.status === 1) {
                resolve(data.data)
            } else {
                reject({ message: data.message })
            }
        })
        .catch((error) => {
            reject(error)
        });
})
export const deleteList = (payload) => new Promise((resolve, reject) => {
    post(`/v1/process/delete-list`, payload)
        .then((data) => {
            if (data.status === 1) {
                resolve()
            } else {
                reject()
            }
        })
        .catch((error) => {
            reject(error)
        });
})

export const getListStats = (payload) => new Promise((resolve, reject) => {
    post(`/v1/stats/list`, payload)
        .then((data) => {
            if (data.status === 1) {
                resolve(data.data)
            } else {
                reject({ message: data.message })
            }
        })
        .catch((error) => {
            reject(error)
        });
})

export const getStatsNonListed= () => new Promise((resolve, reject) => {
    get(`/v1/stats/ungrouped`)
        .then((data) => {
            if (data.status === 1) {
                resolve(data.data)
            } else {
                reject({ message: data.message })
            }
        })
        .catch((error) => {
            reject(error)
        });
})

export const getStatsGroupedByList = () => new Promise((resolve, reject) => {
    get(`/v1/stats/lists-grouped`)
        .then((data) => {
            if (data.status === 1) {
                resolve(data.data)
            } else {
                reject({ message: data.message })
            }
        })
        .catch((error) => {
            reject(error)
        });
})

export const validateBulkEmails = payload => new Promise((resolve, reject) => {
    const headers = new Headers()
    headers.append("Content-Type", "application/json")
    headers.append("Accept", "application/json")
    headers.append("Authorization", `Bearer ${getToken()}`)

    var requestOptions = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(payload),
        redirect: 'follow'
    };

    fetch(`${Config.WS_API_ROOT}/jobs/validate/list`, requestOptions)
        .then(response => response.json())
        .then(response => {
            if(response.success) resolve(response)
            else reject(response)
        })
        .catch(error => reject({message: error}));
})

export const terminateListValidation = payload => new Promise((resolve, reject) => {
    const headers = new Headers()
    headers.append("Content-Type", "application/json")
    headers.append("Accept", "application/json")
    headers.append("Authorization", `Bearer ${getToken()}`)

    var requestOptions = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(payload),
        redirect: 'follow'
    };

    fetch(`${Config.WS_API_ROOT}/jobs/terminate`, requestOptions)
        .then(response => response.json())
        .then(response => {
            if(response.success) resolve(response)
            else reject(response)
        })
        .catch(error => reject({message: error}));
})

