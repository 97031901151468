import {
    combineReducers
  } from 'redux';
  // import { routerReducer } from 'react-router-redux';
  import storage from 'redux-persist/lib/storage';
  import UsersReducer from './usersReducer';
  import CreditsReducer from './creditsReducer';
  import ReportReducer from './reportReducer';
  import EmailsListReducer from './emailsListReducer';
  
  const appReducer = combineReducers({
    users: UsersReducer,
    credits: CreditsReducer,
    report: ReportReducer,
    emailsList: EmailsListReducer
  });

  const rootReducer = (state, action) => {
    if (action.type === 'USER_LOGOUT') {
      storage.removeItem('persist:root')
      return appReducer({}, action)
    }
    return appReducer(state, action)
  }
  
  export default rootReducer;