import { post } from '../utils/api'
import Config from '../utils/configuration'
import { getToken } from '../utils/authentication'

const uploadFile = file => new Promise((resolve, reject) => {
    if (!file) {
        return reject({ message: 'File is required' })
    }

    const formData = new FormData()
    formData.append('userfile', file)

    const headers = new Headers()
    headers.append("Authorization", `Bearer ${getToken()}`)

    var requestOptions = {
        method: 'POST',
        headers: headers,
        body: formData,
        redirect: 'follow'
    };

    fetch(`${Config.API_ROOT}/v1/upload/csv`, requestOptions)
        .then(response => response.json())
        .then(response => {
            if (response.status === 1) resolve(response.data)
            else reject({ message: response.message })
        })
        .catch(error => {
            reject({ message: error })
        });
})

const downloadFile = url => new Promise((resolve, reject) => {
    if (!url) return

    var requestOptions = {
        headers: {
            Authorization: `Bearer ${getToken()}`
        }
    };

    var filename = ""
    var contentType = ""
    fetch(url, requestOptions)
        .then(response => {
            contentType = response.headers.get('Content-Type')
            const header = response.headers.get('Content-Disposition');
            if (!!header) {
                const parts = header.split(';');
                filename = parts[1].split('=')[1].replaceAll("\"", "");
            }
            return contentType === 'application/json' ? response.json() : response.blob();
        })
        .then(data => {
            if (contentType === 'application/json') {
                reject(data)
            } else if(data != null) {
                var url = window.URL.createObjectURL(data);
                if (!!filename) {
                    var a = document.createElement('a');
                    a.href = url;
                    a.download = filename;
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                } else {
                    window.location.assign(url);
                }
                resolve()
            }
        })
        .catch(error => reject({ message: error }));
})

export default {
    uploadFile,
    downloadFile
}