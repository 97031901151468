import {
    UPDATE_VALIDATED_EMAILS_COUNT,
    UPDATE_VERIFIED_EMAILS_COUNT,
    SAVE_VALIDATED_EMAIL_STATUS,
    GET_STATS_NON_LISTED_REQUEST,
    GET_STATS_NON_LISTED_SUCCESS,
    GET_STATS_NON_LISTED_FAILURE,
    GET_STATS_GROUPED_BY_LIST_REQUEST,
    GET_STATS_GROUPED_BY_LIST_SUCCESS,
    GET_STATS_GROUPED_BY_LIST_FAILURE
} from '../actions/reportActions'

// Initial state
const initialState = { loading: false, error: false, validatedEmailsStatus: {} };

// Reducer
export default function ReportReducer(state = initialState, action = {}) {
    let validatedEmailsStatus = state.validatedEmailsStatus

    switch (action.type) {
        case GET_STATS_NON_LISTED_REQUEST:
        case GET_STATS_GROUPED_BY_LIST_REQUEST:
            return {...state, loading: true, error: false}

        case GET_STATS_NON_LISTED_SUCCESS:
            return {...state, loading: false, nonListStats: action.payload}
        
        case GET_STATS_GROUPED_BY_LIST_SUCCESS:
            return {...state, loading: false, groupedByListStats: action.payload}

        case UPDATE_VALIDATED_EMAILS_COUNT:
            return { ...state, validatedEmailsCount: action.payload };

        case UPDATE_VERIFIED_EMAILS_COUNT:
            return { ...state, verifiedEmailsCount: action.payload };

        case SAVE_VALIDATED_EMAIL_STATUS:
            let newEmailStatus = {}
            newEmailStatus[action.payload.email_address] = action.payload
            return { ...state, validatedEmailsStatus: {...validatedEmailsStatus, ...newEmailStatus} };

        case GET_STATS_NON_LISTED_FAILURE:
        case GET_STATS_GROUPED_BY_LIST_FAILURE:
            return {...state, loading: false, error: action.payload}

        default:
            return state
    }

}