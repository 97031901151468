import * as EmailValidations from '../../services/emailValidations'

export const UPDATE_VALIDATED_EMAILS_COUNT = 'UPDATE_VALIDATED_EMAILS_COUNT'
export const UPDATE_VERIFIED_EMAILS_COUNT = 'UPDATE_VERIFIED_EMAILS_COUNT'
export const SAVE_VALIDATED_EMAIL_STATUS = 'SAVE_VALIDATED_EMAIL_STATUS'
export const GET_STATS_NON_LISTED_REQUEST = 'GET_STATS_NON_LISTED_REQUEST'
export const GET_STATS_NON_LISTED_SUCCESS = 'GET_STATS_NON_LISTED_SUCCESS'
export const GET_STATS_NON_LISTED_FAILURE = 'GET_STATS_NON_LISTED_FAILURE'
export const GET_STATS_GROUPED_BY_LIST_REQUEST = 'GET_STATS_GROUPED_BY_LIST_REQUEST'
export const GET_STATS_GROUPED_BY_LIST_SUCCESS = 'GET_STATS_GROUPED_BY_LIST_SUCCESS'
export const GET_STATS_GROUPED_BY_LIST_FAILURE = 'GET_STATS_GROUPED_BY_LIST_FAILURE'

export function getStatsNonListed() {
    return dispatch => {
        dispatch({ type: GET_STATS_NON_LISTED_REQUEST })
        return EmailValidations.getStatsNonListed().then(data => {
            dispatch({ type: GET_STATS_NON_LISTED_SUCCESS, payload: data })
        }).catch(error => {
            dispatch({ type: GET_STATS_NON_LISTED_FAILURE, payload: error.message })
        });
    };
}

export function getStatsGroupedByList() {
    return dispatch => {
        dispatch({ type: GET_STATS_GROUPED_BY_LIST_REQUEST })
        return EmailValidations.getStatsGroupedByList().then(data => {
            dispatch({ type: GET_STATS_GROUPED_BY_LIST_SUCCESS, payload: data })
        }).catch(error => {
            dispatch({ type: GET_STATS_GROUPED_BY_LIST_FAILURE, payload: error.message })
        });
    };
}

export function updateValidatedEmailsCount(newCount) {
    return dispatch => {
        dispatch({ type: UPDATE_VALIDATED_EMAILS_COUNT, payload: newCount })
    }
}

export function updateVerifiedEmailsCount(newCount) {
    return dispatch => {
        dispatch({ type: UPDATE_VERIFIED_EMAILS_COUNT, payload: newCount })
    }
}

export function saveValidatedEmailStatus(validatedEmailStatus) {
    return dispatch => {
        dispatch({ type: SAVE_VALIDATED_EMAIL_STATUS, payload: validatedEmailStatus })
    }
}