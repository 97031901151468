import * as UsersService from '../../services/users'
import * as RazorPayService from '../../services/razorpay'

import {
  UPDATE_CREDITS_BALANCE
} from './CreditsActions'
import { UPDATE_VALIDATED_EMAILS_COUNT, UPDATE_VERIFIED_EMAILS_COUNT } from './reportActions'
export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST'
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS'
export const USER_LOGIN_FAILURE = 'USER_LOGIN_FAILURE'
export const USER_SIGNUP_REQUEST = 'USER_SIGNUP_REQUEST'
export const USER_SIGNUP_SUCCESS = 'USER_SIGNUP_SUCCESS'
export const USER_SIGNUP_FAILURE = 'USER_SIGNUP_FAILURE'
export const GET_USER_DASHBOARD_DATA_REQUEST = 'GET_USER_DASHBOARD_DATA_REQUEST'
export const GET_USER_DASHBOARD_DATA_SUCCESS = 'GET_USER_DASHBOARD_DATA_SUCCESS'
export const GET_USER_DASHBOARD_DATA_FAILURE = 'GET_USER_DASHBOARD_DATA_FAILURE'
export const GET_INDUSTRIES_REQUEST = 'GET_INDUSTRIES_REQUEST'
export const GET_INDUSTRIES_SUCCESS = 'GET_INDUSTRIES_SUCCESS'
export const GET_INDUSTRIES_FAILURE = 'GET_INDUSTRIES_FAILURE'
export const GET_PROFILE_REQUEST = 'GET_PROFILE_REQUEST'
export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS'
export const GET_PROFILE_FAILURE = 'GET_PROFILE_FAILURE'
export const SAVE_PROFILE_REQUEST = 'SAVE_PROFILE_REQUEST'
export const SAVE_PROFILE_SUCCESS = 'SAVE_PROFILE_SUCCESS'
export const SAVE_PROFILE_FAILURE = 'SAVE_PROFILE_FAILURE'
export const CREATE_TEMP_ORDER_REQUEST = 'CREATE_TEMP_ORDER_REQUEST'
export const CREATE_TEMP_ORDER_SUCCESS = 'SCREATE_TEMP_ORDER_SUCCESS'
export const CREATE_TEMP_ORDER_FAILURE = 'CREATE_TEMP_ORDER_FAILURE'
export const SEND_OTP_REQUEST = 'SEND_OTP_REQUEST'
export const SEND_OTP_SUCCESS = 'SEND_OTP_SUCCESS'
export const SEND_OTP_FAILURE = 'SEND_OTP_FAILURE'
export const VERIFY_OTP_REQUEST = 'VERIFY_OTP_REQUEST'
export const VERIFY_OTP_SUCCESS = 'VERIFY_OTP_SUCCESS'
export const VERIFY_OTP_FAILURE = 'VERIFY_OTP_FAILURE'
export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST'
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS'
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE'
export const USER_LOGOUT = 'USER_LOGOUT'


export function login(data, callback) {
  return dispatch => {
    dispatch({ type: USER_LOGIN_REQUEST })
    return UsersService.login(data).then(res => {
      dispatch({ type: USER_LOGIN_SUCCESS, payload: res.user })
      if (callback) {
        callback({ status: true, data: res.user })
      }
    }).catch(error => {
      dispatch({ type: USER_LOGIN_FAILURE, payload: error.message })
      if (callback) {
        callback({ status: false, data: error.message })
      }
    });
  };
}

export function signup(data, callback) {
  return dispatch => {
    dispatch({ type: USER_SIGNUP_REQUEST })
    return UsersService.signup(data).then(res => {
      dispatch({ type: USER_SIGNUP_SUCCESS, payload: res.user })
      if (callback) {
        callback({ status: true, data: res.user })
      }
    }).catch(error => {
      dispatch({ type: USER_SIGNUP_FAILURE, payload: error.message })
      if (callback) {
        callback({ status: false, data: error.message })
      }
    });
  };
}

export function sendResetPasswordLink(payload, callback) {
  return dispatch => {
    return UsersService.sendResetPasswordLink(payload).then(() => {
      callback({ status: true })
    }).catch(error => {
      callback({ status: false, data: error.message })
    });
  };
}

export function resetPassword(payload, callback) {
  return dispatch => {
    return UsersService.resetPassword(payload).then(() => {
      callback({ status: true })
    }).catch(error => {
      callback({ status: false, data: error.message })
    });
  };
}

export function getIndustries() {
  return dispatch => {
    dispatch({ type: GET_INDUSTRIES_REQUEST })
    return UsersService.getIndustries().then(data => {
      const industries = {}
      data.map(industry => industries[industry.id] = industry.name)
      dispatch({ type: GET_INDUSTRIES_SUCCESS, payload: industries })
    }).catch(error => {
      dispatch({ type: GET_INDUSTRIES_FAILURE, payload: error.message })
    });
  };
}
export function getProfile(callback) {
  return dispatch => {
    dispatch({ type: GET_PROFILE_REQUEST })
    return UsersService.getProfile().then(data => {
      dispatch({ type: GET_PROFILE_SUCCESS, payload: data })
      if (callback) {
        callback({ status: true, data: data })
      }
    }).catch(error => {
      dispatch({ type: GET_PROFILE_FAILURE, payload: error.message })
      if (callback) {
        callback({ status: false, data: error.message })
      }
    });
  };
}

export function saveProfile(payload, callback) {
  return dispatch => {
    dispatch({ type: SAVE_PROFILE_REQUEST })
    return UsersService.saveProfile(payload).then(data => {
      dispatch({ type: SAVE_PROFILE_SUCCESS, payload: data })
      if (callback) {
        callback({ status: true, data: data })
      }
    }).catch(error => {
      dispatch({ type: SAVE_PROFILE_FAILURE, payload: error.message })
      if (callback) {
        callback({ status: false, data: error.message })
      }
    });
  };
}

export function changePassword(payload, callback) {
  return dispatch => {
    dispatch({ type: CHANGE_PASSWORD_REQUEST })
    return UsersService.changePassword(payload).then(() => {
      dispatch({ type: CHANGE_PASSWORD_SUCCESS })
      if (callback) {
        callback({ status: true })
      }
    }).catch(error => {
      dispatch({ type: CHANGE_PASSWORD_FAILURE, payload: error.message })
      if (callback) {
        callback({ status: false, data: error.message })
      }
    });
  };
}


export function sendOtp(callback) {
  return dispatch => {
    dispatch({ type: SEND_OTP_REQUEST })
    return UsersService.sendOtp().then(res => {
      dispatch({ type: SEND_OTP_SUCCESS })
      if (callback) {
        callback({ status: true, data: res })
      }
    }).catch(error => {
      dispatch({ type: SEND_OTP_FAILURE, payload: error.message })
      if (callback) {
        callback({ status: false, data: error.message })
      }
    });
  };
}

export function verifyOtp(data, callback) {
  return dispatch => {
    dispatch({ type: VERIFY_OTP_REQUEST })
    return UsersService.verifyOtp(data).then(res => {
      dispatch({ type: VERIFY_OTP_SUCCESS })
      if (callback) {
        callback({ status: true })
      }
    }).catch(error => {
      dispatch({ type: VERIFY_OTP_FAILURE, payload: error.message })
      if (callback) {
        callback({ status: false, data: error.message })
      }
    });
  };
}

export function getDashboardData() {
  return dispatch => {
    dispatch({ type: GET_USER_DASHBOARD_DATA_REQUEST })
    return UsersService.getDashboardData().then(data => {
      dispatch({ type: GET_USER_DASHBOARD_DATA_SUCCESS, payload: data })
      dispatch({ type: UPDATE_CREDITS_BALANCE, payload: data.creditBalance })
      dispatch({ type: UPDATE_VALIDATED_EMAILS_COUNT, payload: data.validatedEmailsCount })
      dispatch({ type: UPDATE_VERIFIED_EMAILS_COUNT, payload: data.verifiedEmailsCount })
    }).catch(error => {
      dispatch({ type: GET_USER_DASHBOARD_DATA_FAILURE, payload: error.message })
    });
  };
}

export function createTempOrder(payload, callback) {
  return dispatch => {
    dispatch({ type: CREATE_TEMP_ORDER_REQUEST })
    return RazorPayService.createTempOrder(payload).then(data => {
      dispatch({ type: CREATE_TEMP_ORDER_SUCCESS, payload: data })
      if (callback) {
        callback({ status: true, data: data })
      }
    }).catch(error => {
      dispatch({ type: CREATE_TEMP_ORDER_FAILURE, payload: error.message })
      if (callback) {
        callback({ status: false, data: error.message })
      }
    });
  };
}

export function logout(data, callback) {
  return dispatch => {
    UsersService.logout().then(data => {
      dispatch({ type: USER_LOGOUT })
      callback(data)
    });
  }
}