import FileService from '../../services/file'
import * as EmailsValidator from '../../services/emailValidations'

export const UPLOAD_CSV_FILE_REQUEST = 'UPLOAD_CSV_FILE_REQUEST'
export const UPLOAD_CSV_FILE_SUCCESS = 'UPLOAD_CSV_FILE_SUCCESS'
export const UPLOAD_CSV_FILE_FAILURE = 'UPLOAD_CSV_FILE_FAILURE'
export const GET_QUEUED_LIST_REQUEST = 'GET_QUEUED_LIST_REQUEST'
export const GET_QUEUED_LIST_SUCCESS = 'GET_QUEUED_LIST_SUCCESS'
export const GET_QUEUED_LIST_FAILURE = 'GET_QUEUED_LIST_FAILURE'
export const ENQUEUED_LIST_REQUEST = 'ENQUEUED_LIST_REQUEST'
export const ENQUEUED_LIST_SUCCESS = 'ENQUEUED_LIST_SUCCESS'
export const ENQUEUED_LIST_FAILURE = 'ENQUEUED_LIST_FAILURE'
export const GET_LIST_STATS_REQUEST = 'LIST_STATS_REQUEST'
export const GET_LIST_STATS_SUCCESS = 'LIST_STATS_SUCCESS'
export const GET_LIST_STATS_FAILURE = 'LIST_STATS_FAILURE'
export const SET_LIST_STATS = 'SET_LIST_STATS'
export const VALIDATE_EMAILS_REQUEST = 'VALIDATE_EMAILS_REQUEST'
export const VALIDATE_EMAILS_SUCCESS = 'VALIDATE_EMAILS_SUCCESS'
export const VALIDATE_EMAILS_FAILURE = 'VALIDATE_EMAILS_FAILURE'
export const DELETE_LIST_REQUEST = 'DELETE_LIST_REQUEST'
export const DELETE_LIST_SUCCESS = 'DELETE_LIST_SUCCESS'
export const DELETE_LIST_FAILURE = 'DELETE_LIST_FAILURE'
export const TERMINATE_LIST_VALIDATION_REQUEST = 'TERMINATE_LIST_VALIDATION_REQUEST'
export const TERMINATE_LIST_VALIDATION_SUCCESS = 'TERMINATE_LIST_VALIDATION_SUCCESS'
export const TERMINATE_LIST_VALIDATION_FAILURE = 'TERMINATE_LIST_VALIDATION_FAILURE'

export function uploadCsvFile(file, callback) {
  return dispatch => {
    dispatch({ type: UPLOAD_CSV_FILE_REQUEST })
    return FileService.uploadFile(file).then(fileData => {
      dispatch({ type: UPLOAD_CSV_FILE_SUCCESS, payload: fileData })
      if (callback) {
        callback({ status: true, payload: fileData })
      }
    }).catch(error => {
      dispatch({ type: UPLOAD_CSV_FILE_FAILURE, payload: error.message })
      if (callback) {
        callback({ status: false, data: error.message })
      }
    });
  };
}

export function getQueuedLists(callback) {
  return dispatch => {
    dispatch({ type: GET_QUEUED_LIST_REQUEST })
    return EmailsValidator.getQueuedLists().then(lists => {
      dispatch({ type: GET_QUEUED_LIST_SUCCESS, payload: lists })
      if (callback) {
        callback({ status: true, data: lists })
      }
    }).catch(error => {
      dispatch({ type: GET_QUEUED_LIST_FAILURE, payload: error.message })
      if (callback) {
        callback({ status: false, data: error.message })
      }
    });
  };
}

export function enqueueList(listId, callback) {
  return dispatch => {
    dispatch({ type: ENQUEUED_LIST_REQUEST })
    return EmailsValidator.enqueueList({emailListId: listId}).then(data => {
      dispatch({ type: ENQUEUED_LIST_SUCCESS, payload: data })
      if (callback) {
        callback({ status: true, data: data })
      }
    }).catch(error => {
      dispatch({ type: ENQUEUED_LIST_FAILURE, payload: error.message })
      if (callback) {
        callback({ status: false, data: error.message })
      }
    });
  };
}

export function deleteList(listId, callback) {
  return dispatch => {
    dispatch({ type: DELETE_LIST_REQUEST })
    return EmailsValidator.deleteList({emailListId: listId}).then(() => {
      dispatch({ type: DELETE_LIST_SUCCESS })
      if (callback) {
        callback({ status: true })
      }
    }).catch(() => {
      dispatch({ type: DELETE_LIST_FAILURE })
      if (callback) {
        callback({ status: false })
      }
    });
  };
}

export function getListStats(listId, callback) {
  return dispatch => {
    dispatch({ type: GET_LIST_STATS_REQUEST })
    return EmailsValidator.getListStats({emailListId: listId}).then(data => {
      dispatch({ type: GET_LIST_STATS_SUCCESS, payload: data })
      if (callback) {
        callback({ status: true, data: data })
      }
    }).catch(error => {
      dispatch({ type: GET_LIST_STATS_FAILURE, payload: error.message })
      if (callback) {
        callback({ status: false, data: error.message })
      }
    });
  };
}

export function validateBulkEmails(listId, callback) {
  return dispatch => {
    dispatch({ type: VALIDATE_EMAILS_REQUEST })
    return EmailsValidator.validateBulkEmails({emailListId: listId}).then(data => {
      dispatch({ type: VALIDATE_EMAILS_SUCCESS })
      if (callback) {
        callback({ status: true })
      }
    }).catch(error => {
      dispatch({ type: VALIDATE_EMAILS_FAILURE, payload: error.message })
      if (callback) {
        callback({ status: false, data: error.message })
      }
    });
  };
}

export function terminateListValidation(listId, callback) {
  return dispatch => {
    dispatch({ type: TERMINATE_LIST_VALIDATION_REQUEST })
    return EmailsValidator.terminateListValidation({emailListId: listId}).then(data => {
      dispatch({ type: TERMINATE_LIST_VALIDATION_SUCCESS })
      if (callback) {
        callback({ status: true, data: data })
      }
    }).catch(error => {
      dispatch({ type: TERMINATE_LIST_VALIDATION_FAILURE, payload: error.message })
      if (callback) {
        callback({ status: false, data: error.message })
      }
    });
  };
}

export function setListStats(listStats) {
  return dispatch => {
    dispatch({ type: SET_LIST_STATS, payload: listStats })
  };
}