import * as EmailValidationService from '../../services/emailValidations'

export const VALIDATE_EMAIL_REQUEST = 'VALIDATE_EMAIL_REQUEST'
export const VALIDATE_EMAIL_SUCCESS = 'VALIDATE_EMAIL_SUCCESS'
export const VALIDATE_EMAIL_FAILURE = 'VALIDATE_EMAIL_FAILURE'


export function validateEmail(email, callback) {
    return dispatch => {
      dispatch({type: VALIDATE_EMAIL_REQUEST})
      return EmailValidationService.validateEmail({email}).then(data => {
        dispatch({type: VALIDATE_EMAIL_SUCCESS, payload: data})
        if (callback) {
          callback({status: true, data: data})
        }
      }).catch(error => {
        dispatch({type: VALIDATE_EMAIL_FAILURE, payload: error.message})
        if (callback) {
          callback({status: false, data: error.message})
        }
      });
    };
  }