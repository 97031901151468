import React, { Component, Suspense, lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { isUserLoggedIn } from '../utils/authentication';

import Spinner from '../app/shared/Spinner';
import PrivateRoute from '../components/Auth/PrivateRoute';

const Login = lazy(() => import('./auth/Login'));
const Signup = lazy(() => import('./auth/Signup'));
const EmailVerify = lazy(() => import('./auth/EmailVerify'));
const ResetPassword = lazy(() => import('./auth/ResetPassword'));
const Dashboard = lazy(() => import('./dashboard/Dashboard'));
const Validation = lazy(() => import('./validation/index.js'));
const Report = lazy(() => import('./report/index.js'));
const CreditsHistory = lazy(() => import('./credits/index.js'));
const Pricing = lazy(() => import('./pricing/index.js'));
const Account = lazy(() => import('./account/index.js'));

const Error404 = lazy(() => import('./error-pages/Error404'));
const Error500 = lazy(() => import('./error-pages/Error500'));


class AppRoutes extends Component {
  render() {
    return (
      <Suspense fallback={<Spinner />}>
        <Switch>
          <PrivateRoute path="/app" isAuthenticated={isUserLoggedIn()}>
            <Route path="/app/dashboard" component={Dashboard} />
            <Route path="/app/validation" component={Validation} />
            <Route path="/app/credits" component={CreditsHistory} />
            <Route path="/app/report" component={Report} />
            <Route path="/app/pricing" component={Pricing} />
            <Route path="/app/account" component={Account} />
          </PrivateRoute>
          <Route path="/login" component={Login} />
          <Route path="/signup" component={Signup} />
          <Route path="/verify-email" component={EmailVerify} />
          <Route path="/reset-password" component={ResetPassword} />
          <Route path="/error-404" component={Error404} />
          <Route path="/error-500" component={Error500} />
          <Redirect exact from="/" to={isUserLoggedIn() ? '/app/dashboard' : '/login'} />
          <Redirect from="*" to="/error-404" />
        </Switch>
      </Suspense>
    );
  }
}

export default AppRoutes;