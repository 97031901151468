import {
    USER_LOGIN_REQUEST,
    USER_LOGIN_SUCCESS,
    USER_LOGIN_FAILURE,
    GET_USER_DASHBOARD_DATA_REQUEST,
    GET_USER_DASHBOARD_DATA_SUCCESS,
    GET_USER_DASHBOARD_DATA_FAILURE,
    USER_LOGOUT,
    GET_INDUSTRIES_REQUEST,
    GET_INDUSTRIES_FAILURE,
    GET_INDUSTRIES_SUCCESS,
    USER_SIGNUP_REQUEST,
    USER_SIGNUP_FAILURE,
    USER_SIGNUP_SUCCESS,
    SEND_OTP_REQUEST,
    SEND_OTP_SUCCESS,
    SEND_OTP_FAILURE,
    VERIFY_OTP_REQUEST,
    VERIFY_OTP_SUCCESS,
    VERIFY_OTP_FAILURE,
    CREATE_TEMP_ORDER_REQUEST,
    CREATE_TEMP_ORDER_SUCCESS,
    CREATE_TEMP_ORDER_FAILURE
} from '../actions/UserActions'

// Initial state
const initialState = {};

// Reducer
export default function UsersReducer(state = initialState, action = {}) {

    switch (action.type) {

        case USER_LOGIN_REQUEST:
        case USER_SIGNUP_REQUEST:
        case GET_USER_DASHBOARD_DATA_REQUEST:
        case GET_INDUSTRIES_REQUEST:
        case SEND_OTP_REQUEST:
        case VERIFY_OTP_REQUEST:
        case CREATE_TEMP_ORDER_REQUEST:
            return {...state, error: false, loading: true};

        case USER_LOGIN_SUCCESS:
        case USER_SIGNUP_SUCCESS:
            return {...state, error: false, loading: false, user: action.payload};

        case GET_INDUSTRIES_SUCCESS:
            return {...state, error: false, loading: false, industries: action.payload};

        case SEND_OTP_SUCCESS:
        case VERIFY_OTP_SUCCESS:
        case CREATE_TEMP_ORDER_SUCCESS:
            return {...state, error: false, loading: false};

        case GET_USER_DASHBOARD_DATA_SUCCESS:
            return {...state, error: false, loading: false, ...action.payload};

        case USER_LOGIN_FAILURE:
        case USER_SIGNUP_FAILURE:
        case GET_USER_DASHBOARD_DATA_FAILURE:
        case GET_INDUSTRIES_FAILURE:
        case SEND_OTP_FAILURE:
        case VERIFY_OTP_FAILURE:
        case CREATE_TEMP_ORDER_FAILURE:
            return {...state, error: action.payload, loading: false};

        default:
            return state
    }

}