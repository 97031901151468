import { applyMiddleware, createStore } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import monitorReducersEnhancer from './enhancers/monitorReducer'
import loggerMiddleware from './middleware/logger'
import rootReducer from '../reducers'

export default function configureStore() {
  // const middlewares = [loggerMiddleware, thunkMiddleware]
  const middlewares = [thunkMiddleware]
  const middlewareEnhancer = applyMiddleware(...middlewares)

  const enhancers = [middlewareEnhancer, monitorReducersEnhancer]
  const composedEnhancers = composeWithDevTools(...enhancers)

  const store = createStore(rootReducer, {}, composedEnhancers)

  // if (process.env.NODE_ENV !== 'production' && module.hot) {
  //   module.hot.accept('../reducers', () => store.replaceReducer(persistedReducer))
  // }

  return { store }
}