import React, { Component } from 'react';

class Footer extends Component {
  render () {
    return (
      <footer className="footer">
        <div className="footer-content">
          <span className="text-center text-sm-left d-block d-sm-inline-block">Copyright © <a href="https://emailvalidify.com" target="_blank" rel="noopener noreferrer">emailvalidify.com </a>2024</span>
          <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Email Validator by PKCY Group</span>
        </div>
      </footer> 
    );
  }
}

export default Footer;