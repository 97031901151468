import LS from './localStorageHelper';

const AUTHENTICATION_STORAGE_KEY = 'EmailChecker:Authentication';
const REMEMBER_ME_STATUS_STORAGE_KEY = 'EmailChecker:RememberMe'
const LOGGED_USER = 'EmailChecker:LoggedUser'

export function storeUser(user) {
    return LS.setItem(LOGGED_USER, user);
}

export function getUser() {
    return LS.getItem(LOGGED_USER);
}

export function isUserLoggedIn() {
    return !!getToken();
}

export async function clearUser() {
    return LS.removeItem(LOGGED_USER);
}

export async function clearData() {
    return LS.removeItem('persist:root');
}

export function getToken() {
    return LS.getItem(AUTHENTICATION_STORAGE_KEY);
}

export async function storeToken(token) {
    return LS.setItem(AUTHENTICATION_STORAGE_KEY, token);
}

export async function clearToken() {
    return LS.removeItem(AUTHENTICATION_STORAGE_KEY);
}

export async function setUserRememberMeStatus(isRememberMe) {
    return LS.setItem(
        REMEMBER_ME_STATUS_STORAGE_KEY,
        isRememberMe ? 'yes' : 'no',
    );
}

export async function getUserRememberMeStatus() {
    return LS.getItem(REMEMBER_ME_STATUS_STORAGE_KEY);
}