import { get, post } from '../utils/api'
import { storeUser, storeToken, clearToken, clearUser } from '../utils/authentication'

export const login = (data) => new Promise((resolve, reject) => {
    if (!(data.email && data.password)) {
        return reject({ message: 'Please enter your email and password' })
    }
    post('/v1/auth/login', { username: data.email, password: data.password })
        .then((data) => {
            if (data.status === 1) {
                storeUser(data.data.user)
                storeToken(data.data.token)
                resolve(data.data)
            } else {
                reject({ message: data.message })
            }
        })
        .catch((error) => {
            reject(error)
        });
})

export const signup = (payload) => new Promise((resolve, reject) => {
    post('/v1/auth/signup', payload)
        .then((data) => {
            if (data.status === 1) {
                storeUser(data.data.user)
                storeToken(data.data.token)
                resolve(data.data)
            } else {
                reject({ message: data.message })
            }
        })
        .catch((error) => {
            reject(error)
        });
})

export const sendResetPasswordLink = (payload) => new Promise((resolve, reject) => {
    post(`/v1/auth/send-reset-password-link`, payload)
        .then((data) => {
            if (data.status === 1) {
                resolve()
            } else {
                reject({ message: data.message })
            }
        })
        .catch((error) => {
            reject(error)
        });
})

export const resetPassword = (payload) => new Promise((resolve, reject) => {
    post(`/v1/auth/reset-password`, payload)
        .then((data) => {
            if (data.status === 1) {
                resolve()
            } else {
                reject({ message: data.message })
            }
        })
        .catch((error) => {
            reject(error)
        });
})

export const getIndustries = () => new Promise((resolve, reject) => {
    get(`/v1/industries`)
        .then((data) => {
            if (data.status === 1) {
                resolve(data.data)
            } else {
                reject({ message: data.message })
            }
        })
        .catch((error) => {
            reject(error)
        });
})

export const getProfile = () => new Promise((resolve, reject) => {
    get(`/v1/user/me`)
        .then((data) => {
            if (data.status === 1) {
                resolve(data.data)
            } else {
                reject({ message: data.message })
            }
        })
        .catch((error) => {
            reject(error)
        });
})

export const saveProfile = (payload) => new Promise((resolve, reject) => {
    post(`/v1/user/save-profile`, payload)
        .then((data) => {
            if (data.status === 1) {
                resolve(data.data)
                storeUser(data.data)
            } else {
                reject({ message: data.message })
            }
        })
        .catch((error) => {
            reject(error)
        });
})

export const changePassword = (payload) => new Promise((resolve, reject) => {
    post(`/v1/user/change-password`, payload)
        .then((data) => {
            if (data.status === 1) {
                resolve()
            } else {
                reject({ message: data.message })
            }
        })
        .catch((error) => {
            reject(error)
        });
})

export const sendOtp = () => new Promise((resolve, reject) => {
    post(`/v1/user/send-otp`, {})
        .then((data) => {
            if (data.status === 1) {
                resolve(data)
            } else {
                reject({ message: data.message })
            }
        })
        .catch((error) => {
            reject(error)
        });
})

export const verifyOtp = (payload) => new Promise((resolve, reject) => {
    post(`/v1/user/verify-otp`, payload)
        .then((data) => {
            if (data.status === 1) {
                resolve()
            } else {
                reject({ message: data.message })
            }
        })
        .catch((error) => {
            reject(error)
        });
})

export const getDashboardData = () => new Promise((resolve, reject) => {
    get(`/v1/stats/dashboard`)
        .then((data) => {
            if (data.status === 1) {
                resolve(data.data)
            } else {
                reject({ message: data.message })
            }
        })
        .catch((error) => {
            reject(error)
        });
})

export const getCreditsBalance = () => new Promise((resolve, reject) => {
    get(`/v1/stats/credits-balance`)
        .then((data) => {
            if (data.status === 1) {
                resolve(data.data?.creditsBalance)
            } else {
                reject({ message: data.message })
            }
        })
        .catch((error) => {
            reject(error)
        });
})

export const getCreditsSpendHistory = () => new Promise((resolve, reject) => {
    get(`/v1/stats/credits-spend-history`)
        .then((data) => {
            if (data.status === 1) {
                resolve(data.data)
            } else {
                reject({ message: data.message })
            }
        })
        .catch((error) => {
            reject(error)
        });
})

export const getCreditsPurchaseHistory = () => new Promise((resolve, reject) => {
    get(`/v1/stats/credits-purchase-history`)
        .then((data) => {
            if (data.status === 1) {
                resolve(data.data)
            } else {
                reject({ message: data.message })
            }
        })
        .catch((error) => {
            reject(error)
        });
})

export const logout = () => new Promise((resolve, reject) => {
    post('/v1/auth/logout').then(() => {
        clearUser()
        clearToken()
        resolve()
    }).catch((error) => {
        reject({ message: 'Logout Failed' })
    });
})